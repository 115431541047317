@import '../../variables';

.paperModal {
  width: 449px !important;
  padding: 24px !important;
  outline: none !important;
  position: absolute !important;
  box-shadow:
          0 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12) !important;
  border-radius: 10px !important;
  background-color: $white !important;
}

.accessModal {
  width: 448px !important;
  padding: 40px 0 32px !important;

  & svg {
    top: 18px !important;
    right: 18px !important;
  }
}

.accessText {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin: 0 96px 32px;
  text-align: center;
}

.accessButtonContainer {
  display: flex;
  justify-content: center;
}
