@import '../../../../../variables';

.wrap {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center
}

.text {
  margin-top: 24px;
  max-width: 360px;
  line-height: 22px;
  font-size: 14px;
  text-align: center;
  margin-bottom: 24px;
}

.img {
  display: block;
  margin-bottom: 24px;
}
