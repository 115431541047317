.header {
  display: flex;
  width: 100%;
  margin-bottom: 32px;
  font-size: 24px;
  font-weight: 700;
}

.inputFiledWrapper {
  margin-bottom: 20px;
}
