.wrapper {
  width: 100%;
  height: 100%;
}

.sectionTitle {
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 800;
  color: #3A3F62;
}
