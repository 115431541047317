@import '../../../../../variables';

.wrapper {
  display: flex;
  flex-direction: column;
  height: 84px;
  width: 500px;
  margin-top: 40px;
}

.wrapperBottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  width: 500px;
  margin-top: 16px
}

.wrapperBottomLeft {
  display: flex;
  flex-direction: column;
  width: 500px;
  margin-top: 16px
}

.wrapperBottomRight {
  display: flex;
  flex-direction: row;
  height: 48px;
  width: 400px;
  margin-top: 16px
}

.text {
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 800;
  color: #3A3F62
}

.textLight {
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #3A3F62
}

.arrowWrapper {
  margin-bottom: 3px
}

.paperModal {
  width: 449px !important;
  height: fit-content !important;
  padding: 24px !important;
  outline: none !important;
  position: absolute !important;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12) !important;
  border-radius: 10px !important;
  background-color: #fff !important;
}

.negativeResult {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px 4px 8px;
  background-color: #11C314;
  width: fit-content;
  height: 24px;
  border-radius: 10px;
  margin-top: 8px;

  color: #002D5E;
  font-size: 12px;
  font-weight: 400;
}

.vusResult {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px 4px 8px;
  background-color: $cornflower-blue;
  width: fit-content;
  height: 24px;
  border-radius: 10px;
  margin-top: 8px;

  color: #002D5E;
  font-size: 12px;
  font-weight: 400;
}

.positiveResult {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px 4px 8px;
  background-color: $persimmon-red;
  width: fit-content;
  height: 24px;
  border-radius: 10px;
  margin-top: 8px;

  color: #002D5E;
  font-size: 12px;
  font-weight: 400;
}

.dateTime {
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: $comet-blue;
  margin-top: 8px;
}

.resultDeliveryContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  font-weight: 700;
  color: #3A3F62
}
