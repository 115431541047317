@import "../../../../variables";

div.tooltip {
    background-color: $mine-shaft;
    padding: 8px;
    border-radius: 10px;
    font-size: 14px;
  }


div.modalContainer {
  width: 650px;
  top: 30%;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}