.wrapper {
  margin-top: 16px;
}

.photoWrapper {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
}

.secondPlaceholder {
  margin-left: 8px;
}
