@import '../../../../variables';

.headerWrapper {
  margin-top: 37px;
  display: flex;
  align-items: center;
  min-width: 260px;
  justify-content: space-between;
  flex-basis: 50%;
}

.title {
  font-family: Lato, sans-serif;
  font-weight: bold;
  font-size: 24px;
  color: $fiord-blue;
  margin-right: 32px;
}

.buttonWrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.addNewUserButton {
  background: linear-gradient(312.17deg, $cornflower-blue 3.33%, $ribbon-blue 96.71%);
  box-shadow: 0 3px 9px rgba(19, 69, 186, 0.206267);
  height: 40px;
  width: 40px;
  padding: 0;
  min-width: 40px;
  border-radius: 25px;
}

.addTitle {
  font-family: Lato, sans-serif;
  font-weight: bold;
  font-size: 14px;
  color: $fiord-blue;
  margin: 0 0 0 16px;
}

.root {
  width: 100% !important;
  box-shadow: none !important;
}

.tableWrapper {
  overflow-x: auto !important;
  background: rgb(247, 250, 254) !important;
  min-width: 500px !important;
  padding-right: 40px !important;
}
