.orgchart.myChart {
  background-image: none !important;
}

.orgchart.myChart > ul > li > ul li::before {
  border-top-color: #215a88;
}

.orgchart.myChart > ul > li > ul li .oc-node::before,
.orgchart.myChart ul li .oc-node:not(:only-child)::after {
  background-color: #215a88;
}
.orgchart ul li .oc-node.selected{
  background-color: transparent;

}

.orgchart ul li .oc-node:hover {
  background-color: transparent;
}

.orgchart ul li .oc-node .username:hover {
  background-color: transparent;
  cursor: pointer;
}


.orgchart ul li .oc-node {
  border: none;
}

.orgchart-container{
  margin: inherit;
  background-color: transparent;
  height: 250px;
  width: 500px;
  border: none;
  border-radius: unset;
}

.orgchart.myChart .oc-node .username {
  border-radius: 5px;
  color: #215a88;
  box-sizing: border-box;
  background-color: #DBDEE8;
  width: 180px;
  height: 50px;
  border: 1px solid #215a88;
}

.orgchart ul li .oc-node .current:hover {
  background-color: transparent;
  cursor: default;
}

.current {
  background-color: #fff !important;
}

.orgchart{
  display: contents;
}
