.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20px;
  width: 100%;
  margin-top: 12px;
}

.text {
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 700;
  margin-left: 8px;
  color: #3A3F62;
}

.arrowWrapper {
  margin-bottom: 3px;
}
