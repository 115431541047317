.wrapper {
  display: flex;
  flex-direction: column;
}

.placeholder {
  width: 324px;
  height: 205px;
  background-color: #DBDEE8;
  border-radius: 10px;
}

.image {
  width: 324px;
  height: 205px;
  border-radius: 10px;
  object-fit: contain;
}
