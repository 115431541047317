@import '../../../../variables';

.modalWrapper {
  --timeit-primary-color: #1e90ff;
}

.header {
  display: flex;
  width: 100%;
  margin-bottom: 32px;
  font-size: 24px;
  font-weight: 700;
}

.geneticTestingBlock {
  display: flex;
  flex-direction: column;
  width: 401px;
  height: 49px;
  margin-top: 32px;
}

.geneticTestingTitle {
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: $fiord-blue;
}

.radioGroup {
  display: flex;
  justify-content: space-between;
  width: 128px;
  height: 24px;
}

.resultBlock {
  display: flex;
  flex-direction: column;
  width: 401px;
  height: 81px;
  margin-top: 32px;
}

.resultsTitle {
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: $fiord-blue;
}

.rootRadio {
  color: $mischka-grey;
  font-size: 14px;
  font-family: Lato, sans-serif;

  .label {
    margin: -4px 16px -4px -14px;
  }

  .span [ class *= "label" ] {
    margin-left: 8px;
  }

  .span {
    font-size: 14px;
    font-family: Lato, sans-serif;
  }

  &.checked {
    color: $ribbon-blue;
  }
}

.resultBtnsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 322px;
  height: 40px;
  margin-top: 8px;
  & > div {
    cursor: pointer;
  }
}

.negativeResultBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 24px 14px 24px;
  background-color: #11C314;
  width: 102px;
  height: 40px;
  border-radius: 10px;

  color: $white;
  font-size: 14px;
  font-weight: 600;
}

.vusResultBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 24px 14px 24px;
  background-color: $cornflower-blue;
  width: 102px;
  height: 40px;
  border-radius: 10px;

  color: $white;
  font-size: 14px;
  font-weight: 600;
}

.positiveResultBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 24px 14px 24px;
  background-color: $persimmon-red;
  width: 102px;
  height: 40px;
  border-radius: 10px;

  color: $white;
  font-size: 14px;
  font-weight: 600;
}

.notSelectedResultBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 24px 14px 24px;
  width: 102px;
  height: 40px;
  border: solid 1px;
  border-color: $athens-gray;
  border-radius: 10px;

  color: $comet-blue;
  font-size: 14px;
  font-weight: 600;
}

.dateTitle {
  font-family: Lato, sans-serif;
  color: $comet-blue;
  font-size: 14px;
  font-weight: 400;
}

.dateSelectionWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 401px;
  height: 70px;
  margin-top: 24px;
}

.chooseTimeWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 401px;
  min-height: 45px;
  margin-top: 8px;
  border: 1px solid;
  border-color: $athens-gray;
  border-radius: 10px;
}

.timeSelectorWrapper {
  display: flex;
  margin-top: 24px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $white;
  z-index: 100;
}

.reScheduleTimeSelectorWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $white;
  z-index: 100;
}

.chooseDate {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 401px;
  height: 45px;
  margin-top: 8px;
  border: solid 1px;
  border-color: $athens-gray;
  border-radius: 10px;
}

.chooseDatePlaceholder {
  padding-left: 24px;
  font-family: Lato, sans-serif;
  color: $comet-blue;
  font-size: 14px;
  font-weight: 400;
}

.chooseDatePlaceholderError {
  padding-left: 24px;
  font-family: Lato, sans-serif;
  color: $persimmon-red;
  font-size: 14px;
  font-weight: 400;
}

.iconWrapper {
  padding-right: 16px;
}

.selectFileWrapper {
  margin-top: 32px;
  padding: 16px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 401px;
  border: 1px dashed #ACB0C0;
  border-radius: 10px;

  &.hover {
    background-color: #E7F0FD;
    border-color: $cornflower-blue;
  }
}

.uploadFileImageWrapper {
  display: flex;
  height: 100%;
}

.uploadFileRightSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 297px;
}

.uploadFileTextWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.uploadFileTextBold {
  font-family: Lato, sans-serif;
  color: $fiord-blue;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 8px;
}

.uploadFileTextLight {
  font-family: Lato, sans-serif;
  color: $comet-blue;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 8px;
  line-height: normal;
  white-space: pre-wrap;

  &:last-child {
    margin-bottom: 0;
  }
}

.selectFileBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 102px;
  border: 1px solid;
  border-color: $ribbon-blue;
  border-radius: 10px;
  font-family: Lato, sans-serif;
  color: $ribbon-blue;
  font-size: 16px;
  font-weight: 700;
}

.selectFileBtnError {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 102px;
  border: 1px solid;
  border-color: $persimmon-red;
  border-radius: 10px;
  font-family: Lato, sans-serif;
  color: $persimmon-red;
  font-size: 16px;
  font-weight: 700;
}

.uploadedFileWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 401px;
  height: 83px;
  margin-top: 32px;
}

.fileUploadingProgressWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 401px;
  height: 58px;
}

.fileUploadingProgressRightSection {
  display: flex;
  margin-left: 8px;
  flex-direction: column;
  justify-content: center;
  width: 401px;
  height: 58px;
}

.uploadedFileTitleText {
  font-family: Lato, sans-serif;
  color: $fiord-blue;
  font-size: 14px;
  font-weight: 400;
}

.fileUploadingProgressText {
  font-family: Lato, sans-serif;
  padding-bottom: 4px;
  color: $comet-blue;
  font-size: 12px;
  font-weight: 400;
}

.fileInfoWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.fileSize {
  margin-right: 8px;
  font-family: Lato, sans-serif;
  color: $fiord-blue;
  font-size: 10px;
  font-weight: 400;
}
