@import '../../variables';

.selectTitle {
  font-size: 14px;
  color: $fiord-blue;
  font-weight: 400;
  margin: 24px 0 8px;
}

.errorMessage {
  font-size: 12px;
  position: absolute;
  color: $persimmon-red;
  margin: 0;
}
