@import '../../../../../variables';

.wrapper {
  display: flex;
  flex-direction: column;
  height: 120px;
  width: 500px;
  margin-top: 80px
}

.wrapperBottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
  width: 500px;
  margin-top: 16px;
}

.dateTime {
  margin-left: 16px;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: $fiord-blue;
}

.text {
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 800;
  color: $fiord-blue;
}
