@import '../../../../../variables';

.statusWrapper {
  border-radius: 10px;
  display: inline-block;
  padding: 4px 8px 4px 8px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Lato, sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: black;
}
