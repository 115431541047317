@import '../../../../variables';

.infoFiledWrapper {
  margin-top: 16px;
}

.infoFiledTitle {
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: $fiord-blue;
}

.infoFiledValue {
  font-family: Lato, sans-serif;
  font-size: 14px;
  color: $comet-blue;
  margin-top: 8px;
}

.buttonWrapper {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $ribbon-blue;
  border-radius: 10px;
  padding: 14px 16px 14px 16px;
  width: fit-content;
  height: 48px;
}

.buttonFulledWrapper {
  cursor: default;
  clickable: false;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $ribbon-blue;
  border-radius: 10px;
  padding: 14px 16px 14px 16px;
  width: fit-content;
  height: 48px;
  background-color: #CADFFF;
}

.buttonDisabledWrapper {
  cursor: default;
  clickable: false;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 14px 16px 14px 16px;
  width: fit-content;
  height: 48px;
  background-color: #ACB0C0;
}

.text {
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: $ribbon-blue;
}

.textDisabled {
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: $white;
}
