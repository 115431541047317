@import '../../../../../variables';

.deliverablesSectionWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.sectionTitle {
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 800;
  color: #3A3F62;
}

.sectionWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.sectionWrapperWithButton {
  justify-content: space-between;
  align-items: flex-end;
  width: 220px;
  display: flex;
}

.buttonContainer {
display: flex;
}

.text {
font-family: Lato, sans-serif;
font-size: 14px;
font-weight: 700;
color: $fiord-blue;
margin-left: 8px;
}

.textDisabled {
cursor: default;
font-family: Lato, sans-serif;
font-size: 14px;
font-weight: 700;
color: #ACB0C0;
margin-left: 8px;
}

.buttonsWrapper {
display: flex;
flex-direction: row;
margin: 8px 42px 0 0;

&:last-child {
margin-right: 0;
}
}

.viewButton {
cursor: pointer;
text-decoration: none;
height: 40px;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
}

.viewButtonDisabled {
cursor: default;
text-decoration: none;
height: 40px;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
}

.downloadButton {
cursor: pointer;
text-decoration: none;
height: 40px;
width: 121px;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
margin-left: 8px;
}

.text {
font-family: Lato, sans-serif;
font-size: 14px;
font-weight: 700;
color: $ribbon-blue;
margin-left: 8px;
}

.result {
height: 40px;
width: 128px;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
font-family: Lato, sans-serif;
font-size: 14px;
font-weight: 700;
color: $fiord-blue;
margin-left: 8px;
}

.resultStatus {
font-family: Lato, sans-serif;
font-size: 14px;
font-weight: 700;
color: #E40E0E;
margin-left: 8px;
}
