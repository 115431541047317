.buttonsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 24px;
  height: 112px;
}

.buttonWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.kitWasSent {
  margin-left: 16px;
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-weight: 400;
  width: 97px;
  height: 24px;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  background-color: #A1F2A3;
  padding: 4px 8px 4px 8px;
}

.notAbleToSendGtKit {
  margin-left: 16px;
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-weight: 400;
  width: 147px;
  height: 24px;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  background-color: #FAA8A8;
  padding: 4px 8px 4px 8px;
}
