.paginationWrapper {
  padding-top: 40px !important;
  padding-right: 40px !important;
  background: rgb(247, 250, 254) !important;
}

.root {
  display: flex !important;
  width: 100% !important;
  justify-content: center !important;
  border: none !important;
  padding-top: 40px !important;
  padding-right: 40px !important;
  background: rgb(247, 250, 254) !important;
}

.caption {
  display: none !important;
}
