@import '../../../../../variables';

.sizeWrapper {
  display: flex;
  margin-top: 41px;
  margin-bottom: 20px;
  font-weight: 800;
  font-size: 16px;
  font-family: Lato, sans-serif;
}
