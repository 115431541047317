@import '../../../../../variables';

.contactItemHeader {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  font-family: Lato, sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: $fiord-blue;
  background: #F7FAFE;
  border-top: none;
}

.firstAndLastName {
  padding-left: 16px;
  width: 26%;
}

.userName {
  padding-left: 24px;
  width: 15%;
}

.email {
  padding-left: 24px;
  width: 21.76%;
}

.phone {
  padding-left: 24px;
  width: 12.76%;
}

.status {
  display: flex;
  padding-left: 24px;
  width: 18%;
  padding-right: 8px;
}

.access {
  width: 12%;
  padding-left: 14px;
}
