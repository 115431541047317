@import '../../variables';

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 80px
}

.userInfoText {
  width: 150px;
  height: 44px;
  font-family: Lato, sans-serif;
  font-size: 36px;
  font-weight: 700;
  margin-top: 8px;
  color: $fiord-blue;
}

.contactInfoWrapper {
  padding-right: 83px;
}
