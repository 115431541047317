@import '../../../../../variables';

.contactItem {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-top: 16px;
  padding-bottom: 16px;
  font-family: Lato, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: $comet-blue;
  border-bottom: 1px solid $athens-gray;
  width: 100%;
  border-top: none;

  &__container {
    display: flex;
    background: #F7FAFE;
  }
}

.firstAndLastName {
  padding-left: 16px;
  width: 26%;
}

.userName {
  padding-left: 24px;
  width: 15%;
}

.email {
  padding-left: 24px;
  width: 21.76%;
}

.phone {
  padding-left: 24px;
  width: 12.76%;
}

.numberOfLogins {
  padding-left: 24px;
  width: 12.76%;
}

.totalInteractionTime {
  padding-left: 24px;
  width: 12.76%;
}

.status {
  display: flex;
  padding-left: 24px;
  width: 18%;
  padding-right: 8px;
}

.access {
  width: 12%;
}
