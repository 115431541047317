@import '../../../variables';

.headerWrapper {
  margin-top: 37px;
  display: flex;
  align-items: center;
  min-width: 260px;
  justify-content: space-between;
  flex-basis: 50%;
}

.title {
  font-family: Lato, sans-serif;
  font-weight: 700;
  font-size: 36px;
  color: $fiord-blue;
  margin-right: 32px;
}

.buttonWrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.addNewUserButton {
  background: linear-gradient(312.17deg, $cornflower-blue 3.33%, $ribbon-blue 96.71%) !important;
  box-shadow: 0 3px 9px rgba(19, 69, 186, 0.206267) !important;
  height: 40px !important;
  width: 40px !important;
  padding: 0 !important;
  min-width: 40px !important;
  border-radius: 25px !important;
}

.addTitle {
  font-family: Lato, sans-serif;
  font-weight: bold;
  font-size: 14px;
  color: $fiord-blue;
  margin: 0 0 0 16px;
}
