.buttonsWrapper {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  margin-left: 16px;
}

.viewButton {
  cursor: pointer;
  text-decoration: none;
  height: 40px;
  width: 88px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
}

.viewButtonDisabled {
  cursor: default;
  text-decoration: none;
  height: 40px;
  width: 88px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
}

.downloadButton {
  cursor: pointer;
  text-decoration: none;
  height: 40px;
  width: 121px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.downloadButtonDisabled {
  cursor: default;
  text-decoration: none;
  height: 40px;
  width: 121px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.text {
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #1658F3;
  margin-left: 8px;
}

.textDisabled {
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #ACB0C0;
  margin-left: 8px;
}
